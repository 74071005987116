import { useDispatch } from "react-redux"
import React from "react"
import { removeModal } from "../../redux/actions/ui/ui"
import { StyledModal } from "./Modal.styles"
import Image from "next/image"
import { ModalPropsType } from "../../redux/actions/ui/types"
import useClickOutside from "../../hooks/useClickOutside"

type Props = ModalPropsType

const Modal = ({
    children,
    subtitle,
    title,
    id,
    hideLogo,
    index,
    size,
    hideCross,
    exitOnClickOutside,
    exitAction,
}: Props) => {
    const [ref] = useClickOutside(() => {
        exitOnClickOutside && exitAction && exitAction()
    })

    const dispatch = useDispatch()
    return (
        <StyledModal index={index || 0} size={size}>
            <div className="modalBox" ref={ref} id="modalBox">
                <div className="modal-overlay" />
                <div className="modal-body">
                    <div className="modal-heading">
                        <div className="full-width">
                            {title && <h3 className="modal-title">{title}</h3>}
                            {subtitle && <p className="modal-subtitle">{subtitle}</p>}
                        </div>
                        {!hideCross && (
                            <button className="btn-close" onClick={() => dispatch(removeModal(id))}>
                                <Image src="/icons/cross.svg" width={12} height={12} />
                            </button>
                        )}
                    </div>
                    {!hideLogo && (
                        <div className="logoImage">
                            <Image src="/images/logo.svg" width={128} height={20} alt="logo" />
                        </div>
                    )}
                    <div>{children}</div>
                </div>
            </div>
            <div className="background" />
        </StyledModal>
    )
}

export default Modal
