import Footer from "../../components/Footer"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../redux/reducers"
import Modal from "../Modal"
import TopAnalysis from "../../components/TopAnalysis"
import NewsMentionsSlider from "../../components/NewsMentionsSlider"
import { StyledWrapper } from "./Layout.styles"
import Header from "../../components/Header"
import React, { useEffect } from "react"
import { StyledContentSection, StyledFooterSection } from "../../styles/StyledMainSection"
import queryString from "querystring"
import { ssoSignOnActions } from "../../redux/actions/user/user"
import LoaderWholePage from "../LoaderWholePage/LoaderWholePage"

type Props = {
    children: JSX.Element
    showTopAnalysis?: boolean
    showNewsSlider?: boolean
    hideFooter?: boolean
    withoutSideMenu?: boolean
}

const Layout = ({
    children: childrenComponent,
    showTopAnalysis,
    showNewsSlider,
    hideFooter,
    withoutSideMenu,
}: Props) => {
    const { modal } = useSelector((state: AppState) => state.modal)
    const dispatch = useDispatch()

    useEffect(() => {
        const { oauth_token, oauth_verifier } = queryString.parse(window.location.search.slice(1))

        if (!oauth_token || !oauth_verifier) {
            return
        }

        dispatch(
            ssoSignOnActions.request({
                oauth_token,
                oauth_verifier,
                type: "twitter",
            })
        )
    }, [])

    const { loadingWholePage } = useSelector((app: AppState) => app.user)

    return (
        <>
            <Header />
            {modal?.map(({ children, ...modal }, index) => {
                const Component = children as Element
                return (
                    <Modal key={index} {...modal} index={index}>
                        {Component}
                    </Modal>
                )
            })}
            <StyledWrapper>
                {childrenComponent}

                <StyledContentSection>
                    {showNewsSlider && <NewsMentionsSlider />}
                    {showTopAnalysis && <TopAnalysis />}
                </StyledContentSection>
                <StyledFooterSection withoutSideMenu={withoutSideMenu}>
                    {!hideFooter && <Footer />}
                </StyledFooterSection>
                {loadingWholePage && <LoaderWholePage />}
            </StyledWrapper>
        </>
    )
}

export default React.memo(Layout)
