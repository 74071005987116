import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledNewsMentionsSlider = styled.div`
    background-color: ${colors.white};
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    padding: 15px 8px 10px;

    @media (max-width: 767px) {
        padding: 20px 19px;
    }

    @media (max-width: 575px) {
        margin-bottom: 30px;
    }
`

export const StyledSlide = styled.div`
    background-color: ${colors.lightBackground};
    border: 1px solid ${colors.lineGrey};
    border-radius: 10px;
    box-sizing: border-box;
    height: 100%;
    padding: 15px 15px 8px;
    margin: 0 8px;

    .newsSource {
        margin-top: 12px;
    }

    .newsBody {
        display: block;
        margin-top: 12px;
    }
`
