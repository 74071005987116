import { MouseEvent } from "react"
import { StyledButton } from "./ButtonLink.styles"

type Props = {
    children: JSX.Element | string | Element
    suffix?: boolean
    onClick?: (event: MouseEvent) => void
    type: "main" | "header" | "body" | "clear"
    disabled?: boolean
    block?: boolean
    centered?: boolean
    rtl?: boolean
}

const ButtonLink = ({ children, onClick, type, suffix, disabled, block, centered, rtl }: Props) => {
    return (
        <StyledButton
            onClick={onClick}
            styleType={type}
            suffix={!!suffix}
            disabled={disabled}
            block={block}
            centered={centered}
            rtl={rtl}
        >
            {children}
        </StyledButton>
    )
}

export default ButtonLink
