import styled from "styled-components"
import { colors } from "../../constants/colors"

type StyleProps = {
    styleType: "main" | "header" | "body" | "clear"
    suffix: boolean
    block?: boolean
    centered?: boolean
}

export const StyledButton = styled.button<StyleProps & { rtl?: boolean }>`
    padding: 0;
    border: none;
    font-size: ${({ styleType }) =>
        styleType === "clear" || styleType === "header" ? "inherit" : "18px;"};
    background: none;
    width: ${({ block }) => (block ? "100%" : "")};
    font-weight: ${({ styleType }) => {
        switch (styleType) {
            case "main":
                return 700
            case "body":
            case "header":
                return 600
            case "clear":
                return "inherit"
            default:
                return 600
        }
    }};

    color: ${({ styleType }) => {
        switch (styleType) {
            case "header":
                return "#000000"
            case "main":
                return "#13C384"
            case "body":
                return "#13C384"
            case "clear":
                return colors.blue
            default:
                return "#000000"
        }
    }};

    display: ${({ centered }) => (centered ? "flex" : "unset")};
    justify-content: ${({ centered }) => (centered ? "center" : "unset")};

    > * {
        &:last-child {
            ${(props) => props.suffix && "margin: 0 10px 5px !important;"}
        }
    }

    line-height: 1.375;
    cursor: pointer;
    font-style: normal;
    display: inline-flex;
    align-items: center;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    a {
        text-decoration: none;
        color: ${({ styleType }) => {
            switch (styleType) {
                case "header":
                    return "#000000"
                case "main":
                    return "#13C384"
                case "body":
                    return "#13C384"
                default:
                    return "#000000"
            }
        }};
    }

    &:disabled {
        color: #5c5055;
        cursor: not-allowed;
    }
`
