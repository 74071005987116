import styled from "styled-components"
import Slider from "react-slick"

export const StyledSlider = styled(Slider)<{ arrows?: boolean }>`
    margin: ${({ arrows }) => arrows && "0px 35px"};

    .slick-dots li.slick-active button:before,
    .slick-dots li button:before {
        color: #13c384;
        height: 14px;
        width: 14px;
        background-color: #13c384;
        border-radius: 50%;
    }

    .slick-dots {
        position: unset;
        margin-top: 8px;
    }

    .slick-prev:before,
    .slick-next:before {
        color: #13c384;
        font-size: 30px;
    }
    .slick-prev,
    .slick-next {
        width: 30px;
        height: 30px;
    }

    .slick-next {
        right: -25px;
        z-index: 1;
    }
    .slick-prev {
        left: -25px;
        z-index: 1;
    }
`

export const StyledClaimSlider = styled(StyledSlider)`
    .slick-track {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    max-width: 325px;

    @media (max-width: 450px) {
        max-width: 250px;
    }

    @media (max-width: 350px) {
        max-width: 200px;
    }
`

export const StyledContentLikelihoodSlider = styled.div<{ height?: number | boolean }>`
    margin-bottom: 18px;

    .slick-list {
        overflow: unset;
        clip-path: inset(0px 0px -999px 0px);
    }

    .slick-slide > div {
        height: ${({ height }) => (height ? `${height}px` : "unset")};
        display: flex;
        justify-content: center;
    }
`
