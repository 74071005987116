import { News } from "../types"

export const newsMentions: News[] = [
    {
        title: "Rootclaim Launches Open Analysis Platform That Surpasses Human Reasoning",
        source: "PR Newswire",
        link: "https://www.prnewswire.com/news-releases/rootclaim-launches-open-analysis-platform-that-surpasses-human-reasoning-604970666.html",
        body: "A substantial body of research has shown that the human brain is unreliable when it comes to accurately assessing complex problems. This means the only way to navigate a sea of half-truths is to complement humanity's fallible intuition with objective probabilistic analysis.",
    },
    {
        title: "Anti-Fraud Experts Launch News-Accuracy Site, Find U.S. Probably Blamed Wrong Side for Syria Chemical Attack",
        source: "U.S. News",
        link: "https://www.usnews.com/news/articles/2016-12-06/anti-fraud-experts-launch-news-accuracy-site-find-us-probably-blamed-wrong-side-for-syria-chemical-attack",

        body: "In applying the fraud-detection approach, Rootclaim seeks to break news events into similar bite-sized pieces and assign values to the individual pieces of evidence, factoring for uncertainty and source reliability. The individual pieces are then loaded into an algorithm that draws big-picture conclusions.",
    },
    {
        title: "Fighting Fake News - Proof and Probability",
        source: "Richard Gutjahr",
        link: "https://www.gutjahr.biz/en/2016/12/rootclaim/",

        body: "The crowd can always improve, can always check everything. It’s like an open-source software. Addressing all the evidence and details of these issues requires research and we rely on the horsepower of the crowd to get it done.",
    },
    {
        title: "Fact or Fiction? Israeli Startup Pops Fake News Conspiracy Bubbles",
        source: "Huffington Post",
        link: "https://www.huffpost.com/entry/fact-or-fiction-israeli-startup-pops-fake-news-conspiracy_b_58c6bb9be4b070e55af9f179",

        body: "The basic reason that we fail at reaching sound conclusions for complex problems is that we base our decisions on filtered information, cherry-picked evidence from biased and interested sources, which is implicitly filtered by us. On top of that, there are a slew of situations where human intuition fails us miserably.",
    },
    {
        title: "Bravo!",
        source: "Nassim Nicholas Taleb",
        link: "https://twitter.com/nntaleb/status/850082838363996161",

        body: "While MSM & policymakers are childishly driven by images, this man is starting a v. rigorous process!!!",
    },
    {
        title: "Rootclaim in Academia",
        source: "City University of New York",
        link: "https://philosophy169.wordpress.com/",

        body: "Rootclaim is featured in Dr. Marcelo Di Bello’s Critical Reasoning course of fall 2017.",
    },
    {
        title: "Israeli Startup Develops 'Ultimate Truth Machine'",
        source: "Haaretz",
        link: "https://www.haaretz.com/hblocked?returnTo=https%3A%2F%2Fwww.haaretz.com%2Fisrael-news%2Fisraeli-startup-develops-the-ultimate-truth-machine-1.5457688",

        body: "The startup's goal is to transform people's understanding of complex and controversial matters. And with everyone from heads of state to the media accusing each other of propagating fake news, the timing couldn't have been better.",
    },
    {
        title: "The Software of Truth That Goes Beyond the Limits of Human Logic",
        source: "La Stampa",
        link: "https://www.lastampa.it/tuttoscienze/2017/10/31/news/il-software-della-verita-che-oltrepassa-1.37446519",

        body: "When this complexity grows and when it becomes indispensable to understand each piece of the puzzle to reach a complete overview, classical reasoning is revealed to be basically useless. Yet this limit does not prevent intelligence agencies, researchers or journalists from formulating their own theories.",
    },
    {
        title: "More or Less Right",
        source: "Frankfurter Allgemeine",
        link: "https://www.faz.net/aktuell/feuilleton/debatten/wahrheitsrechner-rootclaim-plusminus-richtig-14563901.html",

        body: 'Can the likelihood of hypotheses be determined from the probabilities of individual pieces of information? The Israeli start-up "Rootclaim" wants to be honest.',
    },
    {
        title: "Website Aims to Explain the Syrian Chemical Attack with Facts and Mathematics",
        source: "Motherboard",
        link: "https://www.vice.com/de/article/538xn8/diese-website-will-den-syrischen-giftgasangriff-mit-fakten-und-mathematik-aufklaren",

        body: "Rootclaim promises to compute the answers to controversial questions as transparently and comprehensibly as possible using complex probabilistic models.",
    },
    {
        title: "German Government Proposes Fines Against Fake News",
        source: "Aftenposten",
        link: "https://www.aftenposten.no/kultur/i/kV9JQ/den-tyske-regjeringen-foreslaar-boeter-mot-falske-nyheter",

        body: "Israeli anti-fraud experts behind Rootclaim have developed a model that uses Bayesian analysis to find out if an assertion is true.",
    },
]
