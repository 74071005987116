import { newsMentions } from "../../constants/newsMentions"
import Slide from "./Slide"
import { StyledSlider } from "../../styles/Slider"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// styles
import { StyledNewsMentionsSlider } from "./NewsMentionsSlider.styles"

const NewsMentionsSlider = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    return (
        <StyledNewsMentionsSlider>
            <StyledSlider {...settings}>
                {newsMentions.map((news, index) => (
                    <Slide news={news} key={index} />
                ))}
            </StyledSlider>
        </StyledNewsMentionsSlider>
    )
}

export default NewsMentionsSlider
