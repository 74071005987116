import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledModal = styled.div<{ index: number; size?: "small" | "default" | "auto" }>`
    .background {
        z-index: ${({ index }) => 100 + index};
        background: #000;
        opacity: 0.5;

        width: 100%;
        height: 100%;
        position: fixed;

        top: 0;
    }
    .modalBox {
        z-index: ${({ index }) => 101 + index};
        opacity: 1;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: ${colors.white};

        max-height: 677px;

        width: ${({ size }) => {
            switch (size) {
                case "small":
                    return "431px"
                case "default":
                    return "100%"
                case "auto":
                    return "unset"
                default:
                    return "100%"
            }
        }};
        max-width: 800px;
        overflow: auto;

        box-shadow: 0 4px 34px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

    .modal-body {
        padding: ${({ size }) => (size === "auto" ? "40px 70px" : "30px")};

        .logoImage {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }
    }

    .modal-heading {
        display: flex;
        justify-content: flex-end;
    }

    .btn-close {
        background: unset;
        border: none;
        cursor: pointer;

        position: ${({ size }) => size === "auto" && "absolute"};
        top: ${({ size }) => size === "auto" && "14px"};
        right: ${({ size }) => size === "auto" && "8px"};

        &:active {
            outline: none;
        }
        &:focus {
            outline: none;
        }
    }

    .full-width {
        width: 100%;

        display: ${({ size }) => size === "auto" && "none"};
    }

    @media (max-width: 992px) {
        .modalBox {
            width: ${({ size }) => {
                switch (size) {
                    case "small":
                        return "431px"
                    case "default":
                        return "90%"
                    case "auto":
                        return "unset"
                    default:
                        return "90%"
                }
            }};
        }
    }

    @media (max-width: 575px) {
        .modalBox {
            box-sizing: border-box;
            border-radius: 0;
            height: 100vh;
            left: 0;
            max-height: 100vh;
            min-height: 100vh;
            top: 0;
            transform: none;
            width: 100%;
        }

        .modal-body {
            box-sizing: border-box;
            height: 100%;
            padding: 18px;

            input {
                font-size: 16px;
            }
        }
    }
`
