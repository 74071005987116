import { useSelector } from "react-redux"
import { AppState } from "../../redux/reducers"
import AdminSignIn from "../../components/Header/Modals/AdminSignIn"

type Props = {
    children: JSX.Element
}

const LayoutEdit = ({ children }: Props) => {
    const { access_token } = useSelector((state: AppState) => state.admin)
    const { isEdit } = useSelector((state: AppState) => state.ui)

    return !isEdit || access_token ? children : <AdminSignIn />
}

export default LayoutEdit
