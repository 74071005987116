import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledPopup = styled.div`
    background: ${colors.white};
    border: 1px solid ${colors.lineGrey};
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    position: absolute;
    padding: 16px 8px;
    right: 0;
    min-width: 156px;
    top: 60px;
    width: 156px;
    z-index: 9;

    > * {
        cursor: pointer;
        height: 30px;
        margin: 0 8px;

        &:hover {
            box-shadow: 0px 8px 20px -5px rgb(0 0 0 / 22%);
        }
    }

    .arrow {
        display: block;
        height: 14px;
        margin: 0.3rem 0;
        position: absolute;
        right: 17px;
        top: -13px;
        width: 12px;

        &:before,
        &:after {
            border-color: transparent;
            border-style: solid;
            content: "";
            display: block;
            position: absolute;
        }

        &:before {
            border-width: 0 10px 10px 10px;
            border-bottom-color: ${colors.lineGrey};
            top: 0;
        }

        &:after {
            border-width: 0 10px 10px 10px;
            border-bottom-color: ${colors.white};
            top: 1px;
        }
    }

    @media (max-width: 575px) {
        left: initial;
        right: 0;

        .arrow {
            left: initial;
            right: 17px;
        }
    }
`
