import { useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import Image from "next/image"

// components
import SubHeader from "./SubHeader"
import SharePopup from "src/reusableComponents/SharePopup"
import ShareHomePage from "src/reusableComponents/ShareIconsBlock/ShareEmailModal/ShareHomePage"
import LoggedUserHeader from "./LoggedUserHeader"
import ButtonLink from "src/reusableComponents/ButtonLink"
import ButtonOutlined from "src/reusableComponents/ButtonOutlined"
import Search from "./Search"
import SignInModal from "./Modals/SignInModal"
import SignUpModal from "./Modals/SignUpModal"

// utils
import { isBrowser } from "src/helpers"
import useClickOutside from "src/hooks/useClickOutside"
import { openSuccessLoginModal } from "./Modals/SuccessLoginModal/helpers"
import { useDispatch, useSelector } from "react-redux"
import { addModal, setHideHeader } from "src/redux/actions/ui/ui"
import { SIGN_IN_MODAL, SIGN_UP_MODAL } from "src/constants/modals"
import { AppState } from "src/redux/reducers"

// styles
import { StyledHeader, StyledOverlay } from "./Header.styles"

const Header = () => {
    const dispatch = useDispatch()
    const { hideHeader } = useSelector((appState: AppState) => appState.ui)

    const { popup } = useSelector((state: AppState) => state.user)

    const openSignInModal = () =>
        dispatch(addModal({ id: SIGN_IN_MODAL, size: "auto", children: <SignInModal /> }))
    const openSignUpModal = () =>
        dispatch(addModal({ id: SIGN_UP_MODAL, children: <SignUpModal />, size: "auto" }))

    const { email, isGuest } = useSelector((state: AppState) => state.user)

    const [showUserMenu, setShowUserMenu] = useState(false)
    const [showSubheaderNav, setShowSubheaderNav] = useState(false)

    const [scrollPosition, setScrollPosition] = useState(0)
    const [showSearch, setShowSearch] = useState(false)
    const [showSearchResults, setShowSearchResults] = useState(false)

    const headerRef = useRef<HTMLElement>(null)

    const handleScroll = ({ type, code = "" }: KeyboardEvent & WheelEvent & TouchEvent) => {
        if (
            type === "keydown" &&
            code !== "ArrowUp" &&
            code !== "ArrowDown" &&
            code !== "PageDown" &&
            code !== "PageUp" &&
            code !== "Space"
        ) {
            return
        }

        const { scrollTop } = document.documentElement

        if (
            !showUserMenu &&
            !showSubheaderNav &&
            !showSearch &&
            !showSearchResults &&
            (headerRef?.current?.offsetHeight || true) < scrollTop
        ) {
            setScrollPosition(scrollTop)
            if (scrollPosition < scrollTop) {
                dispatch(setHideHeader({ hideHeader: scrollPosition <= scrollTop }))
            }
            if (scrollPosition > scrollTop) {
                dispatch(setHideHeader({ hideHeader: scrollPosition < scrollTop }))
            }
            return
        }
        dispatch(setHideHeader({ hideHeader: false }))
    }

    useEffect(() => {
        if (!isBrowser()) {
            return
        }
        window.addEventListener("wheel", handleScroll as any)
        window.addEventListener("keydown", handleScroll as any)
        window.addEventListener("scroll", handleScroll as any)

        return () => {
            window.removeEventListener("wheel", handleScroll as any)
            window.removeEventListener("keydown", handleScroll as any)
            window.removeEventListener("scroll", handleScroll as any)
        }
    })

    const router = useRouter()

    useEffect(() => {
        dispatch(setHideHeader({ hideHeader: false }))
    }, [router])

    useEffect(() => {
        popup && dispatch(addModal(openSuccessLoginModal))
    }, [])

    const toggleUserMenu = () => {
        if (isGuest) {
            return openSignInModal()
        }

        setShowUserMenu(!showUserMenu)
    }

    const toggleSubheaderNav = () => {
        setShowSubheaderNav(!showSubheaderNav)
    }

    const [openSharePopup, setOpenSharePopup] = useState(false)

    const [shareRef] = useClickOutside(() => {
        setOpenSharePopup(false)
    })

    const [userMenuRef] = useClickOutside(() => {
        showUserMenu && toggleUserMenu()
    })

    const isHomePage = isBrowser() && window.location.pathname == "/"

    return (
        <>
            <StyledHeader id="header" hideHeader={hideHeader} ref={headerRef}>
                <div className="header">
                    <Link href={"/"}>
                        <a className="logo">
                            <Image
                                alt="Rootclaim Logo"
                                src="/images/logo.svg"
                                width={128}
                                height={20}
                            />
                        </a>
                    </Link>
                    <div className="rightSide">
                        <Search
                            showSearch={showSearch}
                            setShowSearch={setShowSearch}
                            showSearchResults={showSearchResults}
                            setShowSearchResults={setShowSearchResults}
                        />
                        <div ref={userMenuRef}>
                            <button
                                type="button"
                                className="navToggle userNavToggle"
                                onClick={toggleUserMenu}
                            >
                                <img src="/icons/userBlack.svg" alt="menu icon" />
                            </button>
                            <div className={`userNav ${showUserMenu ? "active" : ""}`}>
                                {email ? (
                                    <LoggedUserHeader />
                                ) : (
                                    <div className="marginLeft link">
                                        <ButtonLink onClick={openSignInModal} type="header">
                                            Login
                                        </ButtonLink>
                                        <ButtonLink onClick={openSignUpModal} type="header">
                                            Sign Up
                                        </ButtonLink>
                                    </div>
                                )}
                            </div>
                        </div>
                        {isHomePage && (
                            <div className="marginLeft shareWrapper">
                                <ButtonOutlined
                                    prefix
                                    onClick={() => setOpenSharePopup(!openSharePopup)}
                                >
                                    <>
                                        <Image
                                            src="/icons/share.svg"
                                            alt="share icon"
                                            width={18}
                                            height={18}
                                        />
                                        <span>Share</span>
                                    </>
                                </ButtonOutlined>
                                {openSharePopup && (
                                    <div ref={shareRef}>
                                        <SharePopup
                                            twitterTitle="New probabilistic analysis site calculates answers to controversial issues. @Rootclaim"
                                            url={window.location.origin}
                                            body={ShareHomePage}
                                            subject={"Rootclaim. Calculating reality."}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <SubHeader
                    toggleSubheaderNav={toggleSubheaderNav}
                    showSubheaderNav={showSubheaderNav}
                />
            </StyledHeader>
            {(showUserMenu || showSubheaderNav) && <StyledOverlay />}
        </>
    )
}

export default Header
