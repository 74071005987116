import { StyledFooter } from "./Footer.styles"
import Link from "next/link"
import Image from "next/image"

const Footer = () => {
    return (
        <StyledFooter id={"footer"}>
            <div className="logoBlock">
                <Link href="/">
                    <a>
                        <Image src="/images/logoWhite.svg" alt="logo" width={90} height={31} />
                    </a>
                </Link>
            </div>
            <div className="linkBlock">
                <a href="mailto: info@rootclaim.com">Contact Us</a>
                <Link href="/privacy-policy">
                    <a>Privacy Policy</a>
                </Link>
                <Link href="/terms-of-service">
                    <a>Terms & Conditions</a>
                </Link>
            </div>

            <div className="socialBlock">
                <a href="https://www.facebook.com/rootclaim" target="_blank" rel="noreferrer">
                    <Image
                        src="/icons/social/facebook.svg"
                        width="20"
                        height="20"
                        alt="facebook logo"
                    />
                </a>
                <a href="https://twitter.com/rootclaim" target="_blank" rel="noreferrer">
                    <Image
                        src="/icons/social/twitter.svg"
                        width="20"
                        height="20"
                        alt="twitter logo"
                    />
                </a>
                <a
                    href="https://www.youtube.com/channel/UCwmkBJ3FdMlIA6Dv9JKfsbQ"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Image
                        src="/icons/social/youtube.svg"
                        width="24"
                        height="17"
                        alt="youtube logo"
                    />
                </a>
            </div>
        </StyledFooter>
    )
}

export default Footer
