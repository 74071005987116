import { StyledPopup } from "./SharePopup.styles"
import ShareIconsBlock from "../ShareIconsBlock"
import { FC } from "react"

type Props = {
    url: string
    body: FC
    subject: string
    twitterTitle?: string
}

const SharePopup = ({ url, body, subject, twitterTitle }: Props) => {
    return (
        <StyledPopup>
            <span className="arrow" />
            <ShareIconsBlock url={url} body={body} subject={subject} twitterTitle={twitterTitle} />
        </StyledPopup>
    )
}

export default SharePopup
