import React from "react"
import ButtonLink from "../../../reusableComponents/ButtonLink"
import { Hits, Index, InstantSearch, SearchBox } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch"
import Hit from "./Hit"
import { StyledSearch } from "./Search.styles"
import { Dispatch, SetStateAction } from "react"
import useClickOutside from "../../../hooks/useClickOutside"

type Props = {
    showSearch: boolean
    setShowSearch: Dispatch<SetStateAction<boolean>>
    showSearchResults: boolean
    setShowSearchResults: Dispatch<SetStateAction<boolean>>
}

const Search = ({ showSearch, setShowSearch, showSearchResults, setShowSearchResults }: Props) => {
    const algoliaClient = algoliasearch(
        process.env.NEXT_PUBLIC_APP_ALGOLIASEARCH_APPLICATION_ID || "",
        process.env.NEXT_PUBLIC_APP_ALGOLIASEARCH_API_KEY || ""
    )
    const searchClient = {
        ...algoliaClient,
        search(requests: any) {
            if (requests.find((x: any) => !!x.params.query)) {
                setShowSearchResults(true)
                return algoliaClient.search(requests)
            }
            setShowSearchResults(false)
        },
    }

    const [wrapperRef] = useClickOutside(() => {
        showSearch && setShowSearch(false)
    })

    const toggleSearch = () => {
        setShowSearch(!showSearch)
        if (showSearchResults) {
            setShowSearchResults(false)
        }
    }

    return (
        <StyledSearch ref={wrapperRef}>
            <div className={`searchWrapper ${showSearch ? "active" : ""}`}>
                <ButtonLink type="header" onClick={() => toggleSearch()}>
                    {showSearch ? (
                        <img
                            className="btn-icon icon-cross"
                            src="/icons/cross.svg"
                            alt="Cross Icon"
                            width={12}
                            height={12}
                        />
                    ) : (
                        <img
                            className="btn-icon icon-search"
                            src="/icons/search.svg"
                            alt="Search Icon"
                            width={18}
                            height={18}
                        />
                    )}
                </ButtonLink>
                {showSearch && (
                    <InstantSearch searchClient={searchClient} indexName="AllAnalysis">
                        <SearchBox />
                        {showSearchResults && (
                            <div className="results">
                                <Index indexName="AllAnalysis">
                                    <Hits hitComponent={Hit} />
                                </Index>
                            </div>
                        )}
                    </InstantSearch>
                )}
            </div>
        </StyledSearch>
    )
}

export default React.memo(Search)
