import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledContentSection = styled.div<{ rtl?: boolean }>`
    margin-left: ${(props) => (props.rtl ? "2%" : "21%")};
    margin-right: ${(props) => (props.rtl ? "21%" : "2%")};

    img {
        object-fit: contain;
    }

    @media (max-width: 992px) {
        box-sizing: border-box;
        margin: 0;
        padding: 0 30px;
    }

    @media (max-width: 767px) {
        padding: 0 15px;
    }
`

export const StyledFooterSection = styled.div<{ rtl?: boolean; withoutSideMenu?: boolean }>`
    padding-left: ${({ rtl, withoutSideMenu }) => (rtl || withoutSideMenu ? "0" : "21%")};
    padding-right: ${({ rtl, withoutSideMenu }) => (rtl && !withoutSideMenu ? "21%" : "0")};
    background: ${colors.colorIcon};

    @media (max-width: 992px) {
        box-sizing: border-box;
        margin: 0;
        padding: 0 30px;
    }
`
