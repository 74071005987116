import styled from "styled-components"
import { colors } from "../../../constants/colors"

export const StyledSubHeader = styled.div`
    .clearButton {
        border: none;
        background: unset;
        padding: 0;
        cursor: pointer;
        text-align: left;
    }

    .nav {
        min-height: 42px;
        display: flex;
        justify-content: space-between;
        margin: 0 2%;
    }

    .link {
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 1.2;
        color: ${colors.black};
        text-decoration: none;

        display: flex;
        align-items: center;
    }

    .activeTab {
        text-decoration: underline;
    }

    .border {
        margin: 10px 0;
        border-right: 1px solid ${colors.lineGrey};
    }

    @media (max-width: 1250px) {
        .link {
            font-size: 14px;
        }
    }

    @media (max-width: 1160px) {
        padding-right: 2%;

        .nav {
            animation: opacity 350ms linear forwards;
            background-color: ${colors.background};
            border-top: 1px solid ${colors.lineGrey};
            box-sizing: border-box;
            display: none;
            margin: 0;
            padding: 0 20px;
            position: absolute;
            right: 0;
            top: 58px;
            width: 100vw;
            z-index: 99;

            &.active {
                display: flex;
                flex-direction: column;
            }
        }
        .clearButton {
            padding: 20px 0;
            border-bottom: 1px solid ${colors.lineGrey};
        }

        .link {
            margin-left: 0;
        }

        .border {
            display: none;
        }
    }

    @media (max-width: 992px) {
        padding-right: 30px;
        .link {
            font-size: 20px;
        }
    }

    @media (max-width: 767px) {
        padding-right: 15px;

        .nav {
            &.active {
                top: 60px;
            }
        }
    }
`
