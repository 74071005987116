import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    getTopAnalysisActions,
    getTopUnpublishedAnalysisActions,
} from "../../redux/actions/main/main"
import { AppState } from "../../redux/reducers"
import { Story } from "../../types"
import { isTablet, isMobile } from "src/helpers"

export const useAnalysis = (): [Story[], boolean] => {
    const dispatch = useDispatch()
    const { topAnalyses, topAnalysesLoader } = useSelector((appState: AppState) => appState.main)
    const { isEdit } = useSelector((appState: AppState) => appState.ui)

    useEffect(() => {
        !topAnalyses?.length &&
            (isEdit
                ? dispatch(
                      getTopUnpublishedAnalysisActions.request({
                          device: isTablet() ? "tablet" : isMobile() ? "mobile" : "desktop",
                      })
                  )
                : dispatch(
                      getTopAnalysisActions.request({
                          device: isTablet() ? "tablet" : isMobile() ? "mobile" : "desktop",
                      })
                  ))
    }, [dispatch])

    return [topAnalyses, topAnalysesLoader || false]
}
