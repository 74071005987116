import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledTopAnalysis = styled.div`
    display: flex;
    padding: 15px 0;

    .itemWrapper {
        padding: 0 6px;
        width: 33.33%;
    }

    .itemWrapper:first-child {
        padding-left: 0;
    }

    .itemWrapper:last-child {
        padding-right: 0;
    }

    @media (max-width: 1100px) {
        flex-wrap: wrap;
        margin: 0;

        .itemWrapper {
            padding-bottom: 15px;
            padding-right: 0;
            width: 100%;
        }
        .itemWrapper:last-child {
            padding-bottom: 0;
        }
    }

    @media (max-width: 575px) {
        padding: 0;
    }
`

export const StyledAnalysisItem = styled.span`
    background-color: #fff;
    box-shadow: 0 4px 34px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;

    .itemImage {
        border-radius: 3px;
        height: 0;
        margin-bottom: 5px;
        padding-bottom: 56.3%;
        position: relative;

        img {
            height: 100%;
            object-fit: cover;
            position: absolute;
            width: 100%;
            border-radius: 10px;
        }
    }

    .itemTitle {
        margin-bottom: 17px;
    }

    .itemFooter {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        padding-top: 7px;
    }

    .imageTitle {
        background-color: ${colors.darkBackground};
        border-radius: 10px;
        bottom: 15px;
        left: 10px;
        right: 10px;
        margin: 0 auto;
        padding: 6px 10px;
        position: absolute;
        z-index: 9;

        * {
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 18px !important;
            line-height: 1.375 !important;
            color: #ffffff !important;
        }

        p {
            margin: 0;
        }
    }

    .commentsCount {
        align-items: center;
        display: flex;

        img {
            margin: 0 5px 5px 0;
            object-fit: contain;
        }
    }

    .readMoreLink:hover {
        color: ${colors.green};
        text-decoration: underline;
    }
`
