import { TopMenuItem, SideMenuItem } from "../types/menu"

export const menuItems: TopMenuItem[] = [
    { href: "/about", name: "About", external: false },
    { href: "https://blog.rootclaim.com/", name: "Blog", external: true },
    { href: "/faq", name: "FAQ", external: false },
    { href: "/think-rootclaim-is-wrong", name: "Think Rootclaim's Wrong?", external: false },
    { href: "/white-paper", name: "5 Flaws of Human Reasoning", external: false },
    { href: "/how-rootclaim-works", name: "How Rootclaim Works", external: false },
    { href: "/rootclaim-track-record", name: "Rootclaim's Track Record", external: false },
    { href: "/rootclaim-challenge", name: "The Rootclaim Challenge", external: false },
]

export const analysisSideMenuItems = [
    { short_name: "introduction" },
    { short_name: "background" },
    { short_name: "hypotheses" },
    { short_name: "starting_point" },
    { short_name: "evidence" },
]

export const claimsSideMenuItems = [
    { short_name: "overview" },
    { short_name: "hypotheses" },
    { short_name: "background" },
    { short_name: "key_evidence" },
    { short_name: "analysis" },
    { short_name: "evidence" },
    { short_name: "starting_point" },
]

export const aboutMenuItems: SideMenuItem[] = [
    { short_name: "about", name: "About Rootclaim", className: "mainItem" },
    { short_name: "missionStatement", name: "Mission Statement", className: "mainItem" },
    { short_name: "forUs", name: "What’s in it for us?", className: "mainItem" },
    { short_name: "whoWeAre", name: "Who Are We? Who funds this?", className: "mainItem" },
]

export const faqMenuItems: SideMenuItem[] = [
    { short_name: "faq", name: "FAQ", className: "mainItem" },
    { short_name: "approach", name: "Understanding Rootclaim’s Approach", className: "mainItem" },
    {
        short_name: "conclusion",
        name: "1. Why is a Rootclaim conclusion more reliable than that of human experts, judges, or journalists?",
        className: "subItem",
    },
    {
        short_name: "rationalWay",
        name: "2. Isn’t Logic the rational way to reach conclusions about complex, real-life problems?",
        className: "subItem",
    },
    {
        short_name: "probabilisticCalculation",
        name: "3. What makes a probabilistic calculation the right way to structure the analysis?",
        className: "subItem",
    },
    {
        short_name: "complexReality",
        name: "4. Rootclaim analyses are a simplified version of the complex reality. How can a simplification tell us anything meaningful?",
        className: "subItem",
    },
    {
        short_name: "analysisBiased",
        name: "5. Can’t this analysis itself be biased if more evidence is provided that supports one side of the issue?",
        className: "subItem",
    },
    {
        short_name: "assignProbabilities",
        name: "6. How can you assign probabilities to past events? They either happened or did not.",
        className: "subItem",
    },
    {
        short_name: "fixedProbability",
        name: "7. Isn’t there such a thing as an objectively correct, fixed probability for a given event?",
        className: "subItem",
    },
    { short_name: "analyses", name: "Understanding Rootclaim analyses", className: "mainItem" },
    {
        short_name: "topicsTypes",
        name: "8. What types of topics can be analyzed on Rootclaim?",
        className: "subItem",
    },
    {
        short_name: "hypothesesConclusion",
        name: "9. What does it mean when a conclusion for one of the hypotheses is X%?",
        className: "subItem",
    },
    {
        short_name: "evidenceEffect",
        name: "10. How does Rootclaim quantify the effect of evidence (5x, 10x, etc.)?",
        className: "subItem",
    },
    {
        short_name: "analysesReferences",
        name: "11. Why do analyses contain references to “missing evidence”?",
        className: "subItem",
    },
    {
        short_name: "priorProbability",
        name: "12. Why is it necessary to look at the “starting point” (“prior probability” in mathematical lingo) of a hypothesis, when we have actual evidence to go on?",
        className: "subItem",
    },
    {
        short_name: "trustConclusion",
        name: "13. How can someone trust a Rootclaim conclusion, without taking the time to verify every detail of the analysis?",
        className: "subItem",
    },
    {
        short_name: "strongEvidence",
        name: "14. Isn’t it possible that strong evidence that runs counter to the Rootclaim conclusions exists but hasn’t been publicized yet (e.g. by an intelligence agency)?",
        className: "subItem",
    },
]

export const thinkMenuItems: SideMenuItem[] = [
    {
        short_name: "thinkRootclaimWrong",
        name: "So you think a Rootclaim conclusion is wrong?",
        className: "mainItem",
    },
    {
        short_name: "whereDidRootclaimGoWrong",
        name: "Where did Rootclaim go wrong?",
        className: "mainItem",
    },
    { short_name: "hypotheses", name: "Hypotheses", className: "subItem" },
    { short_name: "evidence", name: "Evidence", className: "subItem" },
    { short_name: "dependencies", name: "Dependencies", className: "subItem" },
]

export const whitePapersMenuItems: SideMenuItem[] = [
    { short_name: "howRootclaimWorks", name: "White Paper", className: "mainItem" },
    { short_name: "humanErrors", name: "Human Errors", className: "mainItem" },
    {
        short_name: "biasesInterests",
        name: "1. Filtering of evidence - Cherry-picking, biases and interests",
        className: "subItem",
    },
    {
        short_name: "likelihoods",
        name: "2. Reliance on gut feelings and conventional wisdom to assess likelihoods",
        className: "subItem",
    },
    {
        short_name: "plausibility",
        name: "3. Prosecutor's fallacy - Ignoring a hypothesis’s initial plausibility",
        className: "subItem",
    },
    {
        short_name: "probabilities",
        name: "4. Weak intuition for compounded probabilities",
        className: "subItem",
    },
    { short_name: "dependencies", name: "5. Overlooking dependencies", className: "subItem" },
]

export const howRootclaimWorksMenuItems: SideMenuItem[] = [
    { short_name: "howRootclaimWorks", name: "How Rootclaim Works", className: "mainItem" },
    { short_name: "synopsis", name: "Synopsis", className: "mainItem" },
    {
        short_name: "deficienciesOfHumanAnalysis",
        name: "Deficiencies of human analysis",
        className: "mainItem",
    },
    { short_name: "probabilityTheory", name: "Probability theory", className: "mainItem" },
    { short_name: "probabilityTheoryWhatIsMath", name: "Defining math", className: "subItem" },
    {
        short_name: "probabilityTheoryUnderstanding",
        name: "Understanding probability theory",
        className: "subItem",
    },
    {
        short_name: "probabilityTheoryIdentifyingTheChallenge",
        name: "Identifying the challenge",
        className: "subItem",
    },
    { short_name: "probabilityTheoryHowToProceed", name: "How to proceed", className: "subItem" },
    { short_name: "theRootclaimMethod", name: "The Rootclaim method", className: "mainItem" },
    { short_name: "theRootclaimMethodBackground", name: "Background", className: "subItem" },
    { short_name: "theRootclaimMethodHypotheses", name: "Hypotheses", className: "subItem" },
    { short_name: "theRootclaimMethodEvidence", name: "Evidence", className: "subItem" },
    { short_name: "theRootclaimMethodCalculation", name: "Calculation", className: "subItem" },
]

export const trackRecordMenuItems: SideMenuItem[] = [
    { short_name: "performanceAnalysis", name: "Rootclaim's Track Record", className: "mainItem" },
    {
        short_name: "syrianChemicalAttacks",
        name: "Syrian chemical weapon attacks (Ghouta, Khan Sheikhoun)",
        className: "mainItem",
    },
    {
        short_name: "MH17",
        name: "Who shot down Malaysia Airlines flight 17 over Ukraine on July 17, 2014?",
        className: "mainItem",
    },
    { short_name: "MH370", name: "MH 370", className: "mainItem" },
    { short_name: "barryAndHoneySherman", name: "Barry and Honey Sherman", className: "mainItem" },
    {
        short_name: "covid",
        name: "What is the source of COVID-19 (SARS-CoV-2)?",
        className: "mainItem",
    },
    {
        short_name: "MMRVaccine",
        name: "Does the MMR (measles, mumps, and rubella) vaccine cause autism?",
        className: "mainItem",
    },
    { short_name: "whoKilledHaeMinLee", name: "Who killed Hae Min Lee?", className: "mainItem" },
    {
        short_name: "didPakistanKnow",
        name: "Did Pakistan know that Osama Bin Laden was hiding in Abbottabad?",
        className: "mainItem",
    },
]

export const challengeMenuItems: SideMenuItem[] = [
    { short_name: "rootclaimChallenge", name: "The challenge", className: "mainItem" },
    { short_name: "disinformation", name: "Talk is cheap", className: "mainItem" },
    { short_name: "challenge", name: "The Rootclaim challenge", className: "mainItem" },
    { short_name: "challenges", name: "Active challenges", className: "mainItem" },
    { short_name: "additional", name: "Additional topics", className: "mainItem" },
    { short_name: "QA", name: "Q&A", className: "mainItem" },
]

export const privacyMenuItems: SideMenuItem[] = [
    { short_name: "privacyPolicy", name: "Rootclaim Privacy Policy", className: "mainItem" },
    { short_name: "informationWeCollect", name: "Information we Collect", className: "mainItem" },
    { short_name: "howWeUseInformation", name: "How we Use Information", className: "mainItem" },
    { short_name: "protectInformation", name: "How we Protect Information", className: "mainItem" },
    {
        short_name: "transferOfYourInformation",
        name: "Other Uses or Transfer of your Information",
        className: "mainItem",
    },
    {
        short_name: "accessInformation",
        name: "Accessing or deleting your information",
        className: "mainItem",
    },
    { short_name: "cookies", name: "Cookies", className: "mainItem" },
    { short_name: "doNotTrack", name: "Do Not Track", className: "mainItem" },
    { short_name: "children", name: "Children", className: "mainItem" },
    { short_name: "changes", name: "Changes", className: "mainItem" },
    { short_name: "commentsAndQuestions", name: "Comments and Questions", className: "mainItem" },
]

export const termsMenuItems: SideMenuItem[] = [
    { short_name: "termsOfService", name: "Rootclaim Terms of Service", className: "mainItem" },
    { short_name: "site", name: "Site", className: "mainItem" },
    { short_name: "intellectualProperty", name: "Intellectual Property", className: "mainItem" },
    { short_name: "linkedContent", name: "Linked Content", className: "mainItem" },
    { short_name: "userContent", name: "User Content", className: "mainItem" },
    { short_name: "copyrightInfringement", name: "Copyright Infringement", className: "mainItem" },
    { short_name: "disclaimer", name: "Disclaimer", className: "subItem" },
    { short_name: "limitationOfLiability", name: "Limitation of Liability", className: "mainItem" },
    { short_name: "indemnification", name: "Indemnification", className: "mainItem" },
    { short_name: "miscellaneous", name: "Miscellaneous", className: "mainItem" },
]

export const contentPolicyMenuItems: SideMenuItem[] = [
    { short_name: "contentPolicy", name: "Rootclaim Content Policy", className: "mainItem" },
    { short_name: "unwelcomeContent", name: "Unwelcome content", className: "mainItem" },
    { short_name: "enforcement", name: "Enforcement", className: "mainItem" },
]

export const insideCalculationMenuItems: SideMenuItem[] = [
    { short_name: "insideClaim", name: "Inside the Calculation", className: "mainItem" },
    { short_name: "howItWorks", name: "How it works - 3 steps", className: "mainItem" },
    {
        short_name: "breakingDown",
        name: "Breaking down the analysis - An example",
        className: "mainItem",
    },
    { short_name: "hypotheses", name: "Hypotheses", className: "mainItem" },
    {
        short_name: "mutuallyExlusive",
        name: "Mutually exclusive and collectively exhaustive",
        className: "subItem",
    },
    { short_name: "evidence", name: "Evidence", className: "mainItem" },
    { short_name: "essence", name: "The Essence", className: "subItem" },
    { short_name: "startingPoint", name: "Starting point", className: "subItem" },
    { short_name: "sourceReliability", name: "Source reliability", className: "subItem" },
    {
        short_name: "conflictsAmongSources",
        name: "Conflicts among sources and evidence",
        className: "subItem",
    },
    { short_name: "howToAssess", name: "How to assess source reliability", className: "subItem" },
    {
        short_name: "hypothesisToEvidence",
        name: "Hypothesis-to-Evidence Relationship",
        className: "mainItem",
    },
    {
        short_name: "howDoesTheBayesian",
        name: "The calculation: Bayesian Networks",
        className: "mainItem",
    },
    {
        short_name: "bayesianNetworkStructure",
        name: "Bayesian network structure",
        className: "subItem",
    },
    { short_name: "whatIsDependency", name: "What is a dependency?", className: "subItem" },
    {
        short_name: "evidenceAreDependent",
        name: "What happens if two pieces of evidence are dependent?",
        className: "subItem",
    },
    { short_name: "subAnalyses", name: "Sub-analyses", className: "subItem" },
    { short_name: "storylineAssumptions", name: "Storyline assumptions", className: "subItem" },
    {
        short_name: "intoTheCalculation",
        name: "How storyline assumptions are incorporated into the calculation",
        className: "subItem",
    },
]
