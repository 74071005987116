import { useDispatch, useSelector } from "react-redux"
import { LogoutAction } from "../../../redux/actions/user/user"
import { AppState } from "../../../redux/reducers"
import Image from "next/image"
import { StyledLoggedUserHeader } from "./LoggedUserHeader.styles"
import { StyledSubText } from "../../../styles/Text/Text.styles"
import ButtonLink from "../../../reusableComponents/ButtonLink"
import { addModal } from "../../../redux/actions/ui/ui"
import { SIGN_IN_MODAL, SIGN_UP_MODAL } from "../../../constants/modals"
import SignUpModal from "../Modals/SignUpModal"
import Link from "next/link"
import IconWithName from "./IconWithName"
import SignInModal from "../Modals/SignInModal"
import TooltipLight from "../../../reusableComponents/TooltipLight"

const LoggedUserHeader = () => {
    const dispatch = useDispatch()
    const { name, image, isGuest } = useSelector((state: AppState) => state.user)

    const onLogout = async () => {
        dispatch(LogoutAction())
    }

    const openSignUpModal = () => {
        dispatch(addModal({ id: SIGN_UP_MODAL, children: <SignUpModal />, size: "auto" }))
    }

    const openSignInModal = () => {
        dispatch(addModal({ id: SIGN_IN_MODAL, size: "auto", children: <SignInModal /> }))
    }

    return (
        <StyledLoggedUserHeader>
            <div className="avatarNameBlock">
                <ButtonLink
                    type="clear"
                    onClick={isGuest ? openSignInModal : () => undefined}
                    block
                >
                    {isGuest ? (
                        <IconWithName image={image} isGuest={isGuest} name={name} />
                    ) : (
                        <Link href="/profile">
                            <a className="linkWrapper">
                                <IconWithName image={image} isGuest={isGuest} name={name} />
                            </a>
                        </Link>
                    )}
                </ButtonLink>
            </div>

            {isGuest ? (
                <div className="logOutIcon profileNavLink">
                    <ButtonLink onClick={openSignUpModal} type="header" block>
                        Sign Up
                    </ButtonLink>
                </div>
            ) : (
                <>
                    <div className="mobileLogoutHide">
                        <TooltipLight
                            size="auto"
                            title={
                                <div className="logOutIcon profileNavLink hoverEffect">
                                    <ButtonLink type="clear" onClick={onLogout} block>
                                        <Image
                                            src="/icons/logOut.svg"
                                            width={20}
                                            height={20}
                                            className="icon"
                                        />
                                    </ButtonLink>
                                </div>
                            }
                            text="Logout"
                            hideTitle
                        />
                    </div>
                    <div className="logOutIcon profileNavLink hoverEffect desktopHide">
                        <ButtonLink type="clear" onClick={onLogout} block>
                            <>
                                <Image
                                    src="/icons/logOut.svg"
                                    width={20}
                                    height={20}
                                    className="icon"
                                />
                                <StyledSubText className="desktopHide removePaddings">
                                    Log Out
                                </StyledSubText>
                            </>
                        </ButtonLink>
                    </div>
                </>
            )}
        </StyledLoggedUserHeader>
    )
}

export default LoggedUserHeader
