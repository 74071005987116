import { HitType } from "../../../types"
import { StyledBodyMain } from "../../../styles/Text/Text.styles"
import { removeSpaceSymbol } from "src/helpers"

type Props = {
    hit: HitType
}

const Hit = ({ hit }: Props) => {
    const hitName = hit.name && removeSpaceSymbol(hit.name.replace(/(<([^>]+)>)/gi, ""))

    return (
        <a href={hit.url} target="_blank" rel="noreferrer">
            <StyledBodyMain>{hit.question || hit.user_text || hitName}</StyledBodyMain>
        </a>
    )
}

export default Hit
