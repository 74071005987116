import { StyledTopAnalysis } from "./TopAnalysis.styles"
import { useAnalysis } from "./useAnalysis"
import AnalysisItem from "./AnalysisItem"

const TopAnalysis = () => {
    const [analyses, loader] = useAnalysis()

    return (
        <StyledTopAnalysis>
            {analyses &&
                analyses.length > 0 &&
                analyses.map((story) => (
                    <div className="itemWrapper" key={story.id}>
                        <AnalysisItem story={story} />
                    </div>
                ))}
            {loader && <div>Loading...</div>}
        </StyledTopAnalysis>
    )
}

export default TopAnalysis
