import styled from "styled-components"
import { colors } from "../../../constants/colors"

export const StyledSearch = styled.div`
    min-width: 22px;

    .searchWrapper {
        display: flex;
    }

    .results {
        box-sizing: border-box;
        position: absolute;
        background: ${colors.background};
        border: 1px solid ${colors.lineGrey};
        border-radius: 5px;
        padding: 20px;
        max-height: 50vh;
        overflow: auto;
        top: 58px;
        z-index: 50;
        min-width: 268px;
        width: 310px;
        margin-left: 22px;
    }

    .ais-SearchBox-input {
        margin-left: 10px;
        padding: 14px 20px 14px 20px;
        width: 310px;
        border: 1px solid ${colors.lightGrey};
        box-sizing: border-box;
        border-radius: 5px;

        &:focus {
            outline: none;
            border: 1px solid ${colors.grey};
        }

        @media (max-width: 575px) {
            font-size: 16px;
        }
    }

    .ais-SearchBox-submit {
        display: none;
    }

    .ais-SearchBox-reset {
        display: none;
    }

    .ais-Hits-item {
        list-style-type: none;

        a {
            text-decoration: none;
        }
        &:first-child {
            margin-top: 0;
        }
        margin-top: 18px;
    }

    .ais-Hits-list {
        margin: 0;
        padding: 0;
    }

    .btn-icon {
        object-fit: contain;
    }

    .icon-cross {
        height: 12px;
        width: 12px;
    }

    .icon-search {
        height: 18px;
        min-width: 18px;
        width: 18px;
    }

    @media (max-width: 1024px) {
        .active {
            animation: opacity 350ms linear forwards;
            background-color: ${colors.background};
            box-sizing: border-box;
            flex-wrap: wrap;
            left: 0;
            padding: 20px 20px 20px;
            position: absolute;
            top: 59px;
            width: 100%;
            z-index: 99;
        }

        .results {
            animation: opacity 1000ms linear forwards;
            border-left: none;
            border-right: none;
            box-sizing: border-box;
            margin: 20px 0 0;
            padding: 20px 0;
            position: static;
            width: 100%;
        }

        .ais-SearchBox {
            flex: 1;
        }

        .ais-SearchBox-input {
            width: calc(100% - 20px);
        }
    }

    @keyframes slideFromTop {
        from {
            opacity: 0;
            transform: translateY(0px);
        }

        to {
            opacity: 1;
            transform: translateY(38px);
        }
    }

    @keyframes opacity {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @media (max-width: 767px) {
        .active {
            top: 61px;
        }
    }
`
