import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledFooter = styled.footer`
    background: ${colors.colorIcon};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 150px;

    .linkBlock {
        * {
            margin-right: 30px;
        }
        *:last-child {
            margin-right: 0;
        }
        a {
            text-decoration: none;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #fbfbfd;
        }
    }

    .socialBlock {
        * {
            margin-right: 13px;
        }
        *:last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 1024px) {
        @media not all and (min-resolution: 0.001dpcm) {
            padding-bottom: 60px;
        }
    }

    @media (max-width: 767px) {
        .linkBlock {
            a {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 575px) {
        justify-content: flex-start;
        height: auto;

        .logoBlock {
            margin-top: 20px;
        }

        .linkBlock {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 15px 19px 17px;
            width: 100%;

            * {
                margin-bottom: 8px;
                margin-right: 0;
            }
        }

        .socialBlock {
            margin-bottom: 10px;
        }
    }
`
