import React from "react"
import { menuItems } from "../../../constants/menuItems"
import Link from "next/link"
import { StyledSubHeader } from "./SubHeader.styles"
import { useDispatch } from "react-redux"
import { addModal } from "../../../redux/actions/ui/ui"
import { RIDDLE_MODAL_QUESTION_ONE } from "../../../constants/modals"
import RiddlesModalQuestionOne from "../Modals/Ridlles/RiddlesModalQuestionOne"
import { useRiddleExit } from "../../../hooks/useRiddleExit"
import useClickOutside from "../../../hooks/useClickOutside"
import Image from "next/image"
import { isBrowser } from "../../../helpers"
import { v4 as uuidv4 } from "uuid"

type Props = {
    toggleSubheaderNav: () => void
    showSubheaderNav: boolean
}

const SubHeader = ({ toggleSubheaderNav, showSubheaderNav }: Props) => {
    const dispatch = useDispatch()

    const [onExit] = useRiddleExit()

    const openRiddlesModal = () =>
        dispatch(
            addModal({
                id: RIDDLE_MODAL_QUESTION_ONE,
                children: <RiddlesModalQuestionOne />,
                hideLogo: true,
                hideCross: true,
                exitOnClickOutside: true,
                exitAction: onExit,
            })
        )

    const [ref] = useClickOutside(() => {
        showSubheaderNav && toggleSubheaderNav()
    })

    return (
        <StyledSubHeader ref={ref}>
            <div className={`nav ${showSubheaderNav ? "active" : ""}`}>
                {menuItems.map(({ href, name, external }, index) => (
                    <React.Fragment key={uuidv4()}>
                        {external ? (
                            <a
                                href={href}
                                target="_blank"
                                rel="noreferrer"
                                key={index}
                                className="link navLink"
                            >
                                {name}
                            </a>
                        ) : (
                            <Link href={href} key={index}>
                                <a
                                    className={`link navLink ${
                                        isBrowser() &&
                                        window.location.pathname === href &&
                                        "activeTab"
                                    }`}
                                >
                                    {name}
                                </a>
                            </Link>
                        )}
                        <div className="border" />
                    </React.Fragment>
                ))}
                <button className="clearButton link navLink" onClick={openRiddlesModal}>
                    Riddles
                </button>
            </div>
            <button type="button" className="navToggle" onClick={toggleSubheaderNav}>
                {showSubheaderNav ? (
                    <Image width="24" height="24" src="/icons/close.svg" alt="menu icon" />
                ) : (
                    <Image width="28" height="22" src="/icons/menu.svg" alt="menu icon" />
                )}
            </button>
        </StyledSubHeader>
    )
}

export default SubHeader
