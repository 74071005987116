import { Story } from "../../types"
import { format } from "date-fns"
import Link from "next/link"
import React from "react"

// styles
import { StyledBodyMain, StyledSubText } from "../../styles/Text/Text.styles"
import { StyledAnalysisItem } from "./TopAnalysis.styles"

type Props = { story: Story }

const AnalysisItem = ({ story }: Props) => {
    const { comments_count, created_at, question, cover_photo, slug: slugUrl, url, id } = story

    const slug = slugUrl || url || id

    const optimizedImageUrl =
        cover_photo?.image_url && cover_photo.image_url.replace("upload/", "upload/w_700,c_scale/")

    return (
        <Link href={`/analysis/${slug}`}>
            <a>
                <StyledAnalysisItem>
                    <div className="itemImage">
                        <img
                            src={optimizedImageUrl || "/images/background.jpg"}
                            alt={cover_photo?.alt_text || ""}
                        />
                        <StyledBodyMain color="white" weight="bold">
                            <div
                                className="imageTitle"
                                dangerouslySetInnerHTML={{ __html: question }}
                            />
                        </StyledBodyMain>
                    </div>
                    <StyledSubText color="grey">
                        {format(new Date(created_at), "LLL dd, yyyy")}
                    </StyledSubText>
                    <div className="itemFooter">
                        <div className="commentsCount">
                            <img src="/icons/comment.svg" alt="comment icon" />
                            <StyledSubText inline color="grey">
                                {comments_count}
                            </StyledSubText>
                        </div>
                        <span className="readMoreLink">
                            <StyledBodyMain inline weight={600} color="green">
                                Read more
                            </StyledBodyMain>
                        </span>
                    </div>
                </StyledAnalysisItem>
            </a>
        </Link>
    )
}

export default AnalysisItem
