import styled from "styled-components"
import { colors } from "../../constants/colors"

type Props = {
    hideHeader: boolean
}

export const StyledHeader = styled.header<Props>`
    background: #fbfbfd;
    border-bottom: 1px solid ${colors.lineGrey};
    position: sticky;
    top: 0;
    z-index: 99;
    transform: ${({ hideHeader }) => (hideHeader ? "translateY(-110%)" : "translateY(0)")};
    transition: transform 0.3s ease;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 58px;
        margin: 0 2%;
        border-bottom: 1px solid ${colors.lineGrey};
    }

    .marginLeft {
        display: inline-block;
        > * {
            margin-left: 25px;
        }
    }

    .rightSide {
        display: flex;
        align-items: center;
        font-size: 16px;
    }

    .navToggle {
        display: none;
    }

    .overlay {
        animation: opacity 400ms linear forwards;
        background-color: ${colors.overlayBackground};
        height: 100%;
        left: 0;
        position: fixed;
        top: 76px;
        width: 100%;
        z-index: 9;
    }

    .shareWrapper {
        position: relative;
    }

    @keyframes opacity {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @media (max-width: 1160px) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .header {
            border-bottom: 0;
            flex: 1;
            margin: 0 18px 0 2%;
        }

        .navLink {
            border-bottom: 1px solid ${colors.lineGrey};
            box-sizing: border-box;
            padding: 20px 0;
            text-align: left;
            width: 100%;
        }

        .navToggle {
            align-items: center;
            appearance: none;
            background-color: transparent;
            border: none;
            display: inline-flex;
            height: 36px;
            justify-content: center;
            width: 36px;

            &.userNavToggle {
                display: none;
            }

            &:focus {
                outline: none;
            }
        }
    }

    @media (max-width: 992px) {
        .header {
            margin: 0 30px;
        }
    }

    @media (max-width: 767px) {
        .header {
            border-bottom: none;
            height: 60px;
            margin: 0 12px 0 19px;
        }

        .logo {
            display: flex;
            // width: 128px;
        }

        .shareWrapper {
            > button {
                align-items: center;
                display: inline-flex;
                height: 36px;
                margin-left: 16px;
                padding: 0 0 0 4px;
                width: 36px;

                span {
                    display: none;
                }
            }
        }

        .navToggle {
            &.userNavToggle {
                border: 1px solid ${colors.colorIcon};
                border-radius: 3px;
                display: inline-flex;
                margin-left: 16px;
            }
        }

        .userNav {
            background-color: ${colors.background};
            box-sizing: border-box;
            left: 0;
            padding: 0 20px;
            position: absolute;
            top: 60px;
            width: 100%;
            z-index: 99;

            button {
                white-space: nowrap;
            }

            > * {
                display: none;
                flex-direction: column;
            }

            .marginLeft {
                > * {
                    border-bottom: 1px solid ${colors.lineGrey};
                    box-sizing: border-box;
                    font-size: 20px;
                    line-height: 1.2;
                    font-weight: normal;
                    margin-left: 0;
                    padding: 20px 0;
                    text-align: left;
                    width: 100%;
                }
            }

            &.active {
                animation: opacity 350ms linear forwards;
                border-top: 1px solid ${colors.lineGrey};

                > * {
                    display: flex;
                }
            }
        }
    }

    @media (max-height: 650px) {
        .navLink {
            padding: 14px 0;
        }
    }
`

export const StyledOverlay = styled.header`
    animation: opacity 400ms linear forwards;
    background-color: ${colors.overlayBackground};
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;

    @keyframes opacity {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`
