import styled from "styled-components"
import { colors } from "../../../constants/colors"

export const StyledLoggedUserHeader = styled.div`
    display: flex;
    align-items: center;

    .icon {
        display: flex;
        cursor: pointer;
        margin-left: 24px;
    }
    .noImage {
        display: flex;
    }

    .avatar {
        border-radius: 3px;
        width: 45px;
        height: 45px;
        object-fit: cover;
    }

    .name {
        display: flex;
        margin: 3px 0 0 17px;
    }

    .avatarNameBlock {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 9px;
        margin-left: 24px;
    }

    .logOutIcon {
        display: flex;
        cursor: pointer;
        padding: 15px;
        border-radius: 50%;
    }
    .hoverEffect:hover {
        background-color: rgba(60, 64, 67, 0.078);
    }

    .linkWrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .profileNavLink .mobileHide {
        display: flex;
    }

    @media (max-width: 1024px) {
        .mobileHide {
            display: none;
        }
    }

    @media (max-width: 767px) {
        .logOutIcon {
            padding: unset;
            border-radius: unset;
        }
        .hoverEffect:hover {
            background-color: unset;
        }
        .mobileLogoutHide {
            display: none;
        }

        .profileNavLink {
            align-items: center;
            border-bottom: 1px solid ${colors.lineGrey};
            box-sizing: border-box;
            display: flex;
            margin: 0;

            text-align: left;
            width: 100%;

            span {
                margin-left: 12px;
                padding: 20px 0;
                font-size: 20px;
            }

            button {
                padding: 20px 10px;
                width: 100%;
                font-weight: 400;
                font-size: 16px;
            }

            .removePaddings {
                padding: 0;
            }
        }

        .avatar {
            margin-left: 0;
        }
        .avatarNameBlock {
            width: 100%;
        }
    }
`
