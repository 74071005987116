import Image from "next/image"
import { StyledSubText } from "../../../styles/Text/Text.styles"
import TooltipLight from "../../../reusableComponents/TooltipLight"

type Props = {
    image: string
    isGuest: boolean
    name: string
}

const IconWithName: React.FC<Props> = ({ image, isGuest, name }) => {
    const nameTooltip = (name: string) =>
        name?.length > 25 ? (
            <TooltipLight text={name} title={name.slice(0, 25) + "..."} size={"medium"} hideTitle />
        ) : (
            name
        )

    return (
        <>
            <div className="profileNavLink">
                {image ? (
                    <img src={image} alt="Avatar" className="avatar" />
                ) : (
                    <>
                        <div className="noImage mobileHide">
                            <Image src="/icons/noAvatar.svg" width={45} height={45} priority />
                        </div>
                        <div className="desktopHide">
                            <Image src="/icons/userBlack.svg" width={18} height={18} priority />
                        </div>
                    </>
                )}
                <StyledSubText className="desktopHide">My Profile</StyledSubText>
            </div>
            <div className="name mobileHide">
                <StyledSubText>
                    {isGuest ? `Anonymous (${nameTooltip(name)})` : nameTooltip(name)}
                </StyledSubText>
            </div>
        </>
    )
}

export default IconWithName
