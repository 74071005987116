import { News } from "../../types"

// styles
import { StyledBodyMain, StyledSubText } from "../../styles/Text/Text.styles"
import { StyledSlide } from "./NewsMentionsSlider.styles"

type Props = {
    news: News
}

const Slide = ({ news }: Props) => {
    const { title, source, link, body } = news

    return (
        <StyledSlide>
            <a href={link} target="_blank" rel="noreferrer">
                <StyledBodyMain weight="bold">{title}</StyledBodyMain>
                <StyledSubText className="newsBody">{body}</StyledSubText>
                <StyledBodyMain className="newsSource" weight={500}>
                    {source}
                </StyledBodyMain>
            </a>
        </StyledSlide>
    )
}

export default Slide
